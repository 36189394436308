<template>
  <div class="invoice">
   <div class="my_order_rt">
			<div class="my_index_c my_index_c_c">
				<h3>{{this.$t('billInfo')}}</h3>
				<div>
					<ul>
						<li>
							<p><span>*</span> {{this.$t('invoiceHeader')}}：</p>
							<div class="my_index_rt">
								<div class="my_index_rt_2"><input name="" type="text" :placeholder="this.$t('iptInvoiceHeader')"  v-model="invoice.billContent"/></div>
							</div>
							<div class="clear"></div>
						</li>
						<li>
							<p><span>*</span> {{this.$t('duty')}}：</p>
							<div class="my_index_rt">
								<div class="my_index_rt_2"><input name="" type="text" :placeholder="this.$t('iptDuty')" v-model="invoice.taxCode" /></div>
							</div>
							<div class="clear"></div>
						</li>
							<li>
							<p><span>*</span> {{this.$t('mailbox')}}：</p>
							<div class="my_index_rt">
								<div class="my_index_rt_2"><input name="" type="text" :placeholder="this.$t('iptMailbox')" v-model="invoice.receiverEmail" /></div>
							</div>
							<div class="clear"></div>
							<li>
							<p>{{this.$t('Telephone')}}：</p>
							<div class="my_index_rt">
								<div class="my_index_rt_2"><input name="" type="text" :placeholder="this.$t('iptPhone')" v-model="invoice.billPhone" /></div>
							</div>
							<div class="clear"></div>
						</li>
						<li>
							<p>{{this.$t('deposit')}}：</p>
							<div class="my_index_rt">
								<div class="my_index_rt_2"><input name="" type="text" :placeholder="this.$t('iptDeposit')" v-model="invoice.bankName" /></div>
							</div>
							<div class="clear"></div>
						</li>
					
						
						<li>
							<p>{{this.$t('InvoiceType')}}：</p>
							<div class="my_index_rt">
								<div class="my_index_rt_2">
    								<el-select v-model="invoice.billHeader" :placeholder="this.$t('plsSel')">
										<el-option :label="this.$t('General')" value= "0"></el-option>
      									<el-option :label="this.$t('Special')" value= "1"></el-option>
										<el-option :label="this.$t('personal')" value= "2"></el-option>
    								</el-select>
								</div>
							</div>
							<div class="clear"></div>
						</li>
						<li>
							<p>{{this.$t('AccountNum')}}：</p>
							<div class="my_index_rt">
								<div class="my_index_rt_2"><input name="" type="text" :placeholder="this.$t('iptAccountNum')" v-model="invoice.bankAccount" /></div>
							</div>
							<div class="clear"></div>
						</li>
						<li><div class="my_index_rt_4"><input name="" type="button" :value="this.$t('ConfirmAdd')" @click="confirmChange" /></div></li>
					</ul>
				</div>
				<div class="invoice" v-if="billHeaderList.length >0">
			<h3 class="Invoice-title">{{this.$t('HeaderList')}}</h3>
					<div class="scoll">
						<div class="Invoice-list" v-for="item in billHeaderList" :key="item.owid">
					<span>{{$t('invoiceHeader')}}：{{item.billContent}}</span>
					<span>{{$t('duty')}}：{{item.taxCode}}</span>
					<span>{{$t('mailbox')}}：{{item.receiverEmail}}</span>
					<span>{{$t('InvoiceType')}}：{{item.billHeader ==='0'?"普票":item.billHeader ==='1' ?"专票":"个人"}}</span>
						<a @click="editHeader(item)"><img src="@/assets/img/79.png" alt=""></a>
						<a @click="deleteHeader(item.owid)"><img src="@/assets/img/80.png" alt=""></a>
					</div>
					</div>
					</div>
			</div>
		</div>
		
  </div>

</template>

<script>
import { addInvoice ,getBillHeader,deleteBillHeader} from './model'
import { showLayer } from "@/utils/com";
export default {
	data(){
		return {
			invoice:{
				// header:'',
				billHeader:'',
				billContent:'',
				billPhone:'',
				bankName:'',
				bankAccount:'',
				taxCode:'',
				receiverEmail:'',
				owid:''
			},
			billHeaderList:'',
			editOrEelete:false //记录当前修改时添加还是删除
		}
	},
	created(){
		this.getHeader()
	},
	methods:{
		confirmChange(){
			if(this.editOrEelete === true){ //修改
					addInvoice(this.invoice).then(res=>{
						if(res === 'success'){
							this.getHeader()
							showLayer("success", "编辑成功！");
							this.invoice = ''
						}
					})
					this.editOrEelete = false
			}else {// 添加
			if(this.invoice.billContent !== '' && this.invoice.taxCode !== '' && this.invoice.receiverEmail !== ''){
				addInvoice(this.invoice).then(res=>{
				if(res === 'success') {
					showLayer("success", "添加成功！");
					this.invoice.billHeader =''
					this.invoice.billContent =''
					this.invoice.billPhone =''
					this.invoice.bankName =''
					this.invoice.bankAccount =''
					this.invoice.taxCode =''
					this.invoice.receiverEmail =''
					 this.getHeader()
				}
			})
			}else {
				showLayer("toast","发票抬头、邮箱、税号为必填项！")
			}
			}
		},
		getHeader(){
			getBillHeader().then(res=>{
				this.billHeaderList = res
			})
		},
		// 删除
		deleteHeader(owid){
			deleteBillHeader({owids:owid}).then(res=>{
				if(res){
					this.getHeader()
					showLayer("success", "删除成功！");
				}
			})
		},
		// 编辑
		editHeader(item){
			this.invoice.billHeader =item.billHeader
			this.invoice.billContent =item.billContent
			this.invoice.billPhone =item.billPhone
			this.invoice.bankName =item.bankName
			this.invoice.bankAccount =item.bankAccount
			this.invoice.taxCode =item.taxCode
			this.invoice.receiverEmail =item.receiverEmail
			this.editOrEelete = !this.editOrEelete
			this.invoice.owid = item.owid
		}
	}
}
</script>

<style>
.el-input__inner {
	border-radius: 0 !important;
}

</style>