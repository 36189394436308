import { Control,Order} from '@/api/index'

export async function getBillList(data){
    let res = await Control.getBillList(data)
    return res;
}

export async function deleteBill(data){
    let res = await Control.deleteBill(data)
    return res;
}

export async function addInvoice(data){
    let res = await Control.addInvoice(data)
    return res;
}

export async function getBillHeader(data){
    let res = await Order.getBillHeader(data)
    return res;
}

export async function deleteBillHeader(data){
    let res =await Order.deleteBillHeader(data)
    return res;
}
